import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';

import { ReduxState } from 'ducks';

import { TopPageAdditionalImagesSection } from './TopPageAdditionalImageSection';
import { TopPageProductListSlider } from './TopPageProductListSlider';
import { TopPageCustomIntroduction } from './TopPageCustomIntroduction';

export const TopPageCustomSections = () => {
  const { i18n, t } = useTranslation();

  const allCustomLayouts =
    useSelector(
      (state: ReduxState) =>
        state.server.settings.all?.booking_widget_design_params?.top_page_custom_layouts
    ) ?? [];

  const customLayout = allCustomLayouts.find(
    (layout) => layout.language === i18n.language.toUpperCase().replace('-', '_')
  );

  if (!customLayout) {
    return null;
  }

  return (
    <Box width="100%">
      {customLayout.sections.map((section, idx) => {
        if (!section.is_visible) {
          return null;
        }

        switch (section.section_type) {
          case 'ADDITIONAL_IMAGES_SECTION':
            return (
              <TopPageAdditionalImagesSection
                key={idx}
                additionalImages={section.additional_images ?? []}
              />
            );
          case 'RECOMMENDED_PRODUCT_LIST':
            return (
              <TopPageProductListSlider
                key={idx}
                title={t('Recommended')}
                productIds={section.recommended_product_ids ?? []}
              />
            );
          case 'CUSTOM_INTRODUCTION':
            return (
              <TopPageCustomIntroduction
                key={idx}
                messageJSON={section?.custom_introduction?.message ?? ''}
              />
            );
          case 'CUSTOM_PRODUCT_LIST':
            return (
              <TopPageProductListSlider
                key={idx}
                title={section.custom_product_list?.title ?? ''}
                productIds={section.custom_product_list?.product_ids ?? []}
              />
            );
          default:
            return null;
        }
      })}
    </Box>
  );
};
